.contactSlide {
	will-change: trasform;
	background-image: url("../../assets/Contact/ContactBack.svg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.content {
	will-change: trasform;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.content * {
	text-align: center;
}

.content > h1 {
	font-size: 3.5rem;
	margin-top: 3.875rem;
	margin-bottom: 1.56rem;
}

.content > p,
.content a {
	display: block;
	margin: 0 auto;
	max-width: 33.43rem;
	margin-bottom: 2rem;
}

.button {
	display: block;
	margin: 0 auto;
}

.policy {
	position: absolute;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 33.06rem;
	bottom: 2.5rem;
	left: 6rem;
}

.policy > button {
	font-size: 1rem;
	cursor: pointer;
	background: none;
	border: none;
	padding: 0.2rem 1rem;
}

@media (max-width: 800px) {
	.contactSlide {
		background-image: url("../../assets//Contact/ContactMobile.svg");
	}

	.policy {
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 1.2rem;
	}

	.content > h1 {
		font-size: 4rem;
		margin-top: 7rem;
	}

	.content > p,
	.content a {
		font-size: 1.5rem;
		margin-top: 4rem;
	}

	.policy > button {
		font-size: 1.2rem;
		padding: 0.5rem 1rem;
	}

	.button {
		width: 10rem;
		height: 2.5rem;
		font-size: 1rem;
	}
}
