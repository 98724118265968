.contactModal {
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	background-image: url("../../../assets/Contact/ContactBack.svg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.button {
	position: absolute;
	bottom: 1rem;
	right: 3rem;
	height: 2rem;
}

.pdf {
	width: 100%;
	height: 100%;
	overscroll-behavior: contain;
}

@media (max-width: 800px) {
	.contactModal {
		background-image: url("../../../assets/Contact/ContactMobile.svg");
	}

	.button {
		right: 5rem;
		width: 10rem;
		height: 2.5rem;
		bottom: 1rem;
		font-size: 1rem;
	}
}
