.fBasSlide {
	will-change: transform;
	display: grid;
	grid-template-rows: 1fr 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr;
	align-items: center;
	position: relative;
	background-image: url("../../../assets/FABS/FbasBack.svg");
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}

.facebook {
	grid-column: 0;
	grid-row: 2;
	margin: 0 auto;
	width: 15.611rem;
	height: 9.505rem;
	animation: float 2s infinite linear;
}

.text {
	grid-column-start: 2;
	grid-column-end: 4;
	grid-row-start: 1;
	grid-row-end: 4;
	margin-right: 20%;
}

.text > h1 {
	font-weight: bold;
	font-size: 2.8125rem;
}

.text > p {
	font-weight: 500;
	letter-spacing: 0.125rem;
}

.text > * {
	margin-bottom: 1.8rem;
}

.button {
	display: block;
	margin: 0 auto;
}

@keyframes float {
	from {
		transform: translateY(0%);
	}
	25% {
		transform: translateY(-5%);
	}
	50% {
		transform: translateY(0%);
	}
	75% {
		transform: translateY(5%);
	}
	to {
		transform: translateY(0%);
	}
}

@media (max-width: 800px) {
	.fBasSlide {
		grid-template-rows: 1.3fr 0.7fr;
		grid-template-columns: none;
		background-image: url("../../../assets/FABS/FbasMobileBack.svg");
	}

	.text {
		grid-row: 1;
		grid-column-start: 1;
		grid-column-end: 3;
		text-align: left;
		justify-self: start;
		margin-left: 7.5rem;
		margin-top: 3rem;
		margin-right: 7.5rem;
	}

	.text > h1 {
		font-size: 4rem;
	}

	.text > p {
		font-size: 1.8rem;
	}

	.facebook {
		align-self: flex-start;
		width: 19.611rem;
		height: 14.505rem;
		grid-column-start: 1;
		grid-column-end: 3;
	}

	.button {
		position: absolute;
		width: 10rem;
		height: 2.5rem;
		bottom: 2rem;
		right: 6rem;
		font-size: 1rem;
	}
}
