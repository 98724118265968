.animate {
	animation: tremble 1 100ms linear;
}

@keyframes tremble {
	from {
		transform: rotate(0deg);
	}
	25% {
		transform: rotate(5deg);
	}
	50% {
		transform: rotate(0deg);
	}
	75% {
		transform: rotate(-5deg);
	}
	to {
		transform: rotate(0deg);
	}
}
