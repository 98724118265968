.carousel img {
	width: 56rem;
	height: auto;
}

.carousel {
	position: absolute;
	width: 56rem;
	height: auto;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@media (max-width: 800px) {
	.carousel img {
		width: 42.5rem;
	}

	.carousel {
		width: 42.5rem;
	}
}
