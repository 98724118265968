.bsCubeSlide {
	will-change: transform;
	display: grid;
	grid-template-columns: 1fr 1fr;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
}

.logo {
	will-change: transform;
	position: absolute;
	top: 50.5%;
	left: 50.3%;
	transform: translate(-50%, -50%);
	width: 8.125rem;
	height: 8.125rem;
}

@media (max-width: 800px) {
	.bsCubeSlide {
		grid-template-columns: none;
		grid-template-rows: 1fr 1fr;
	}

	.logo {
		width: 7.2rem;
		height: 7.2rem;
		left: 50%;
		top: 50%;
	}
}
