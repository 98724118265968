.nav > ul > li {
	cursor: pointer;
	padding: 1rem;
	display: flex;
	flex-flow: row;
	margin: 1rem;
	text-align: center;
}

.nav > ul {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}

.nav > ul > li:hover {
	text-shadow: 5px 5px 10px white, -5px -5px 10px white, 2px 2px 5px white, -2px -2px 5px white;
}

@media (max-width: 800px) {
	.nav > ul > li {
		font-size: 2rem;
		margin: 0.2rem;
	}

	.nav {
		padding: 1.6rem;
	}
}

@media (max-width: 1000px) {
	.nav > ul > li {
		font-size: 1rem;
		margin: 0.1rem;
	}
}

@media (max-width: 600px) {
	.nav > ul > li {
		font-size: 1.1rem;
		margin: 0;
	}
	.nav {
		padding: 1rem;
	}
}
