.automationsIcon {
	width: 7.275rem;
	height: 4.243rem;
}

.gearOne {
	width: 3.75rem;
	height: 3.75rem;
}

.gearTwo {
	position: relative;
	width: 1.875rem;
	height: 1.875rem;
}

.gearThree {
	width: 1.125rem;
	height: 1.125rem;
	position: relative;
	top: -1.875rem;
	left: -1.875rem;
}

.automationsIcon > svg {
	animation-name: gear;
	animation-duration: 3s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	animation-fill-mode: forwards;
}
.massLaunchIcon {
	display: flex;
	flex-flow: column;
	align-items: center;
}

.rocketBody {
	width: 3.5rem;
	height: 3.875rem;
}

.rocketTail {
	animation-name: rocket;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	animation-fill-mode: forwards;
	width: 1.56rem;
}

.statsIcon {
	width: 5.68rem;
	height: 5.68rem;
	animation-name: stats;
	animation-duration: 3s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	animation-fill-mode: forwards;
}

@keyframes gear {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
@keyframes rocket {
	form {
		transform: translateY(0%);
	}
	50% {
		transform: translateY(70%);
	}
	to {
		transform: translateY(0%);
	}
}

@keyframes stats {
	form {
		transform: translateY(0%);
	}
	50% {
		transform: translateY(20%);
	}
	to {
		transform: translateY(0%);
	}
}
