html {
	box-sizing: border-box;
}
*,
*::before,
*::after {
	box-sizing: inherit;
	margin: 0;
	padding: 0;
	font-family: "Jura", sans-serif;
	color: white;
}

body {
	margin: 0;
	padding: 0;
	background-color: black;
	overflow-x: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
h1 {
	font-size: 2.8rem;
	font-weight: bold;
	margin-top: 2rem;
}
h1,
h2,
h3,
h4,
p {
	letter-spacing: 0.25rem;
	word-spacing: 0.25rem;
	font-family: "Montserrat", sans-serif;
}
p {
	letter-spacing: 0.187rem;
}

button {
	font-family: "Montserrat", sans-serif;
	font-size: 0.625rem;
	font-weight: bold;
	letter-spacing: 0.016rem;
}

#portal {
	width: 100vw;
	height: 0;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 0;
}

.swiper-pagination {
	margin-bottom: 2rem;
}

.swiper {
	margin: 0 !important;
}

.backgroundImg {
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.slideBody {
	width: 57.05rem;
	height: 39.233rem;
}

@media (max-width: 800px) {
	html {
		font-size: 10px;
	}
	.slideBody {
		width: 47.05rem;
		height: 66.18rem;
	}
}

@media (max-width: 600px) {
	html {
		font-size: 7px;
	}
}
