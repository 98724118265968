.graph {
	height: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: start;
}

@media (max-width: 800px) {
	.graph {
		justify-content: center;
	}
}
