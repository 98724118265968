.stats {
	will-change: transform;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: url("../../assets/Stats/StatsBack.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.content {
	display: grid;
	grid-template-rows: 1fr 1fr;
	gap: 2rem;
	align-items: center;
	justify-content: center;
}

@media (max-width: 800px) {
	.stats {
		background-image: url("../../assets/Stats/StatsMobileBack.svg");
	}

	.content {
		gap: 6rem;
	}
}
