.bsCubeTask {
	will-change: transform;
	position: relative;
	width: 30.28rem;
	height: 39.18rem;
	top: 0.781rem;
	right: 1.5rem;
	background-image: url("../../../assets/BrowserCube/BsCubeTaskBack.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.container {
	will-change: transform;
	display: grid;
	grid-template-rows: 1fr 1fr 1fr;
	margin-left: 8.437rem;
}

.text {
	margin-top: 2rem;
	grid-row-start: 2;
	grid-row-end: 4;
}

.text > h3 {
	font-family: "Montserrat", sans-serif;
	font-size: 1.8rem;
	letter-spacing: 0.3125rem;
	margin-bottom: 2.43rem;
}

.text > p {
	font-size: 0.9rem;
	letter-spacing: 0.125rem;
}

.flap {
	position: relative;
	width: 13.437rem;
	height: 12.87rem;
	bottom: 0.4rem;
}

@media (max-width: 800px) {
	.bsCubeTask {
		background-image: url("../../../assets/BrowserCube/BsCubeTaskMobile.svg");
		grid-row: 1;
		width: 47.05rem;
		top: -1rem;
		right: 0rem;
		display: block;
	}

	.container {
		grid-template-rows: none;
		grid-template-columns: 1fr 1fr 1fr;
		margin-left: 5rem;
		margin-top: 5rem;
	}

	.text {
		grid-column-start: 1;
		grid-column-end: 3;
		grid-row-start: 1;
		grid-row-end: 3;
	}

	.text > h3 {
		font-size: 2.5rem;
	}

	.text > p {
		font-size: 1.2rem;
	}

	.flap {
		grid-column-start: 3;
		grid-column-end: 3;
		grid-row-start: 1;
		grid-row-end: 1;
		top: -2.4rem;
		left: -4rem;
	}
}
