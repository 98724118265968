.slide {
	will-change: trasnform;
	margin: 2rem;
	text-align: center;
}
.slide > h2 {
	font-size: 2.5rem;
	padding: 1rem;
	font-weight: 200;
}

.slide > h1 {
	font-size: 10rem;
	font-weight: 200;
}

@media (max-width: 800px) {
	.slide > h1 {
		font-size: 6rem;
		font-weight: 200;
	}
	.slide > h2 {
		font-size: 2rem;
		margin-top: 1rem;
	}
}
