.pdfContainer {
	position: relative;
	width: 52rem;
	height: 28rem;
	overflow: visible;
	border: none;
}

.button {
	display: flex;
	justify-content: center;
}

@media (max-width: 800px) {
	.pdfContainer {
		width: 37.05rem;
		height: 56.18rem;
	}

	.pdfContainer p {
		font-size: 1.5rem;
		margin-top: 4rem;
	}

	.button {
		font-size: 1.5rem;
		margin-top: 2rem;
	}
}
