.bsCubeModal {
	position: relative;
	display: flex;
	align-items: center;
	background-image: url("../../../assets/BrowserCube/BsCubeModalBack.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.container {
	width: 100%;
	height: 90%;
}

.container > h1 {
	font-weight: bold;
	font-size: 2.81rem;
	text-align: center;
}

.carousel {
	position: fixed;
	width: 50%;
	height: 50%;
}

.button {
	position: absolute;
	bottom: 1rem;
	right: 5rem;
}

.imageFlip {
	position: absolute;
	width: 46rem;
	height: auto;
	top: 53%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@media (max-width: 800px) {
	.bsCubeModal {
		background-image: url("../../../assets/BrowserCube/BsCubeMobileModal.svg");
	}
	.container > h1 {
		font-size: 3.5rem;
	}
	.button {
		width: 10rem;
		height: 2.5rem;
		bottom: 2rem;
		font-size: 1rem;
	}
}
