.button {
	position: static;
	background-color: white;
	border: 3px solid;
	border-radius: 5rem;
	width: 8rem;
	height: 2rem;
	stroke: none;
	cursor: pointer;
	font-family: "Montserrat", sans-serif;
	font-size: 0.6rem;
	font-weight: 800;
	letter-spacing: 0.016rem;
	box-shadow: 0px 0px 20px 5px;
	z-index: 10;
}
