.bsCubeDesktop {
	will-change: transform;
	position: relative;
	width: 30.28rem;
	height: 39.18rem;
	bottom: 0.781rem;
	left: 1.43rem;
	background-image: url("../../../assets/BrowserCube/BsCubeDesktopBack.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.container {
	margin-top: 5rem;
	margin-left: 3.31rem;
	margin-right: 7rem;
}

.container h3 {
	font-family: "Montserrat", sans-serif;
	font-size: 2rem;
	letter-spacing: 0.3125rem;
	margin-bottom: 2.43rem;
}

.container p {
	font-size: 0.9rem;
	letter-spacing: 0.125rem;
}

.button {
	display: block;
	margin: 0 auto;
	margin-top: 6rem;
}

@media (max-width: 800px) {
	.bsCubeDesktop {
		background-image: url("../../../assets/BrowserCube/BsCubeDesktopMobile.svg");
		grid-row: 2;
		width: 47.05rem;
		bottom: 11.2rem;
		left: 0rem;
	}

	.container {
		display: grid;
		grid-template-columns: 1fr 2fr;
		justify-content: center;
		align-items: start;
		width: 100%;
		height: 80%;
		margin-left: 0rem;
	}

	.text {
		margin-left: 5rem;
		margin-top: 5rem;
		grid-column-start: 1;
		grid-column-end: 2;
	}

	.text > h3 {
		font-size: 2.5rem;
	}

	.text > p {
		font-size: 1.2rem;
	}

	.button {
		position: absolute;
		margin: 0;
		bottom: 4rem;
		right: 2.5rem;
		width: 10rem;
		height: 2.5rem;
		font-size: 1rem;
	}
}
