.slide {
	will-change: transform;
	position: relative;
	width: 24.45rem;
	height: 25.81rem;
	display: flex;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.container {
	margin-top: 1.43rem;
	margin-bottom: 1.43rem;
}

.icon {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 10.625rem;
}

.container * {
	margin: 0 auto;
}

.container > h2 {
	text-align: center;
	font-size: 2.1875rem;
	font-weight: 700;
	letter-spacing: 0.25rem;
	margin-bottom: 2.06rem;
}

.container > p {
	letter-spacing: 0.125rem;
	font-size: 1rem;
	font-weight: normal;
	text-align: center;
	margin-left: 2.25rem;
	margin-right: 2rem;
}

@media (max-width: 800px) {
	.container {
		margin-top: 1rem;
	}

	.container > p {
		font-size: 1.2rem;
	}

	.container > h2 {
		margin-bottom: 1.06rem;
	}

	.icon {
		height: 10rem;
	}
}
