.container {
	will-change: transform;
	display: flex;
	align-items: center;
	height: 100%;
	justify-content: center;
}

.inactive {
	position: absolute;
	z-index: -2;
}
.hideMenu {
	position: fixed;
	top: 0;
	width: 100vw;
	height: 5rem;
	z-index: 10;
}
