.carousel img {
	width: 56rem;
	height: auto;
}

.carousel {
	position: absolute;
	width: 56rem;
	height: auto;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -42%);
}

@media (max-width: 800px) {
	.carousel img {
		width: 44.05rem;
	}
	.carousel {
		width: 44.05rem;
	}
}
