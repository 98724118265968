.clientsItem {
	will-change: transform;
	position: relative;
	width: 36.44rem;
	height: 25.065rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}

.clientsTop {
	top: 1.2rem;
}

.clientsBottom {
	bottom: 1.2rem;
}

.clientsLeft {
	left: 0.1rem;
}

.clientsRight {
	right: 0.1rem;
}
.clientsRight img {
	grid-column: 2;
}

.clientsTop.clientsLeft {
	background-image: url("../../assets/Clients/Client1Back.svg");
}

.clientsTop.clientsRight {
	background-image: url("../../assets/Clients/Client2Back.svg");
}

.clientsBottom.clientsLeft {
	background-image: url("../../assets/Clients/Client3Back.svg");
}

.clientsBottom.clientsRight {
	background-image: url("../../assets/Clients/Client4Back.svg");
}

.content {
	will-change: transform;
	display: grid;
	grid-auto-flow: column;
	margin: 0 1.93rem;
	align-items: start;
}

.content > img {
	will-change: transform;
	width: 13.625rem;
	height: 13.625rem;
}

.text {
	margin-left: 2.0625rem;
	margin-right: 1rem;
}

.text > * {
	font-family: "Montserrat", sans-serif;
	letter-spacing: 0.0625rem;
}

.text > h4 {
	font-size: 1rem;
	font-weight: 1000;
}

.text > p {
	font-size: 0.8rem;
}

.name {
	margin-bottom: 0.2rem;
}

.business {
	margin-bottom: 1.68rem;
}

.flap {
	position: absolute;
	top: 0.13rem;
	right: 5.8rem;
	width: 10rem;
	height: 5.18rem;
}

@media (max-width: 800px) {
	.clientsItem {
		width: 37.44rem;
		height: 26.065rem;
	}

	.clientsTop {
		top: 0;
	}

	.clientsBottom {
		bottom: 0rem;
	}

	.clientsLeft {
		left: 0rem;
	}

	.clientsRight {
		right: 0rem;
	}

	.contentRight .logo {
		grid-column: 2;
	}

	.clientsTop.clientsLeft {
		top: 2.8rem;
		background-image: url("../../assets/Clients/ClientsMobile.svg");
	}

	.clientsTop.clientsRight {
		top: 1.4rem;
		background-image: url("../../assets/Clients/ClientsMobile.svg");
	}

	.clientsBottom.clientsLeft {
		background-image: url("../../assets/Clients/ClientsMobile.svg");
	}

	.clientsBottom.clientsRight {
		bottom: 1.4rem;
		background-image: url("../../assets/Clients/ClientsMobile.svg");
	}

	.content {
		margin: 0 2rem;
		align-items: center;
	}

	.content > img {
		align-self: center;
		width: 9.625rem;
		height: 9.625rem;
	}

	.text > h4 {
		font-size: 1.2rem;
	}

	.text > p {
		font-size: 1.2rem;
	}

	.business {
		margin-bottom: 1rem;
	}

	.flap {
		left: 1rem;
	}
}
