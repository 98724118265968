.slide {
	will-change: transform;
}

.slide > * {
	text-align: center;
	margin: 0 auto;
}

.slide > h1 {
	font-size: 3.5rem;
	margin-bottom: 2.37rem;
}

.slide > p {
	font-weight: 700;
	max-width: 37.06rem;
	margin-bottom: 6.42rem;
}

.slide > h2 {
	font-size: 2rem;
	max-width: 17.8rem;
}

.logos {
	will-change: transform;
	margin-bottom: 7.5rem;
}

.logos > svg {
	width: 20.37rem;
	height: 10.43rem;
}

.logoTwitter {
	margin: 0 1rem;
	animation: logoAnim 2s infinite linear;
	animation-delay: 300ms;
}

.logoTikTok {
	animation: logoAnim 2s infinite linear;
}

.logoGoogle {
	animation: logoAnim 2s infinite linear;
	animation-delay: 600ms;
}

@keyframes logoAnim {
	from {
		transform: translateY(0rem);
	}
	25% {
		transform: translateY(-1rem);
	}
	50% {
		transform: translateY(0rem);
	}
	75% {
		transform: translateY(1rem);
	}

	to {
		transform: translateY(0rem);
	}
}

@media (max-width: 800px) {
	.slide > p {
		font-size: 1.2rem;
	}
}
