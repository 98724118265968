.reference {
	overflow: hidden;
}

.container {
	will-change: transform;
	overflow: visible;
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	opacity: 1;
	margin: 0 auto;
	position: fixed;
}

.active {
	will-change: transform;
	opacity: 1;
}

.inactive {
	display: none;
}
