.fbasModal {
	position: relative;
	display: flex;
	align-items: center;
	background-image: url("../../../assets/FABS/FbasModalBack.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.container {
	width: 100%;
	height: 92%;
}

.container > h1 {
	text-align: center;
}

.button {
	position: absolute;
	right: 5rem;
	bottom: 1rem;
}

.imageFlip {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 47.1rem;
	height: auto;
}

@media (max-width: 800px) {
	.fbasModal {
		background-image: url("../../../assets/FABS/FbasMobileBack.svg");
	}

	.imageFlip {
		width: 45.5rem;
	}

	.container > h1 {
		font-size: 3.5rem;
	}

	.button {
		width: 10rem;
		height: 2.5rem;
		bottom: 2rem;
		right: 6rem;
		font-size: 1rem;
	}
}
