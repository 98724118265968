.Clients {
	will-change: transform;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
}

@media (max-width: 800px) {
	.Clients {
		grid-template-rows: 1fr 1fr 1fr 1fr;
		grid-template-columns: none;
	}
}
