.checkBoxItem {
	display: grid;
	align-items: center;
	justify-content: center;
	grid-template-columns: 0.3fr 1fr;
}

.checkBoxItem > h3 {
	margin-left: 0.5rem;
	letter-spacing: 0.25rem;
	font-size: 1.375rem;
}

.check {
	width: 3.25rem;
	height: 2.56rem;
	margin: 0 auto;
}

.check > svg {
	width: 100%;
	height: 100%;
}

@media (max-width: 800px) {
	.checkBoxItem {
		grid-template-columns: none;
		grid-template-rows: 1fr 1fr;
		gap: 2rem;
	}

	.check {
		grid-row: 2;
		width: 3.9rem;
		height: 3.1rem;
	}

	.checkBoxItem > h3 {
		font-size: 1.7rem;
		text-align: center;
		letter-spacing: 0.3rem;
		margin-left: none;
	}
}
