.services {
	will-change: transform;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
}

.autoFarm {
	background-image: url("../../assets/Services/AutofarmBack.svg");
}

.massLaunch {
	background-image: url("../../assets/Services/MassLaunchBack.svg");
}

.statsMaster {
	background-image: url("../../assets/Services/StatsMasterBack.svg");
}

@media (max-width: 800px) {
	.services {
		grid-template-columns: none;
		grid-template-rows: 1fr 1fr 1fr;
		gap: none;
	}
}
