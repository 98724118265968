.graphItem {
	margin-bottom: 2.24rem;
}

.textCont {
	margin-bottom: 0.43rem;
	display: flex;
	justify-content: space-between;
}

.textCont > p {
	letter-spacing: 0.25rem;
}

.bar {
	height: 0.9rem;
	border-radius: 0.3125rem;
}

@media (max-width: 800px) {
	.bar {
		height: 1.56rem;
	}

	.graphItem {
		margin-bottom: 4rem;
		margin-left: 2rem;
		margin-right: 2rem;
	}
}
